

















import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract/ui'
import { ImageProps } from '../../../dsl/atoms/Image'
import { toImageProps } from '../../../front/shared/support'

import { UiMarkdown } from '../../partials/UiMarkdown'

import { CTABoxModule } from '../CTABox.contracts'
import { ctaBoxContentFactory } from '../CTABox.factory'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'CTABoxModuleUi', components: { UiMarkdown } })
export class CTABoxModuleUi extends AbstractModuleUi<CTABoxModule> {
  protected defaultContent = ctaBoxContentFactory()

  /**
   * Determines to which direction CTABox should be set.
   */
  public get direction (): string {
    return this.content.direction
  }

  /**
   * Constructs proper ImageProps from image data.
   */
  public get image (): ImageProps {
    if (!this.content.image) {
      throw new Error('CTABoxUi: Image is null!')
    }

    return toImageProps(this.content.image)
  }

  /**
   * Determines whether action link is present.
   */
  public get hasActionLink (): boolean {
    if (typeof this.content.cta === 'undefined') {
      return false
    }

    return typeof this.content.cta.action !== 'undefined' && ('target' in this.content.cta.action)
  }

  /**
   * Determines whether CTABox has CTA section.
   */
  public get hasCTASection (): boolean {
    return this.content.headline.length > 0 || this.content.content.length > 0 ||
      this.hasActionLink
  }

  /**
   * Determines CTABox has headline.
   */
  public get hasHeadline (): boolean {
    return typeof this.content.headline !== 'undefined'
  }

  /**
   * Determines CTABox has content.
   */
  public get hasContent (): boolean {
    return typeof this.content.content !== 'undefined'
  }

  /**
   * Determines whether module can be rendered.
   */
  public get shouldRender (): boolean {
    return this.content.image !== null
  }
}

export default CTABoxModuleUi
